@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.portfolio {
    margin-top: 5%;
    margin-bottom: 5%;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item__main__container {
    margin: 5rem;
    display: flex;
    justify-content: center;
}

.portfolio__item-desc__container {
    display: flex;
}

.portfolio__item__quote {
    width: 50%;
}

.portfolio__item__quote p{
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Permanent Marker', cursive;
    padding-top: 2rem; 
}
.portfolio__item__main {
    background: var(--color-bg-variant);
    padding: 3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    max-width: 1100px;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.portfolio__item-image__main {
    border-radius: 1.5rem;
    overflow: hidden;
    width: 80%;
}

.portfolio__item-image-container {
    display: flex;
    gap: 1rem;
}

.portfolio__item-image-container__main {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    position: relative;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 5/3;
}

.portfolio__item h3 {
    margin: 0.5rem 0 1rem;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

}

.portfolio__item-list {
    padding: 1rem;
}

.portfolio__item-list li {
    margin: 1rem;
    list-style-type: square;
    font-size: 0.9rem;
}

.image-slider__container {
    width: 100%;
    height: 100%;
    position: relative;
}

.image-slider__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    flex-shrinK: 0;
    flex-grow: 0;
    transition: translate 300ms ease-in-out;
}

.image-slider__btn {
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
}

.image-slider__btn:hover {
    background-color: rgb(0,0,0,0.2)
}
.image-slider__btn > * {
    stroke: white;
    fill: black;
    width: 2rem;
    height: 2rem;
}

/******** MEDIUM DEVICES ******/
@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    
}

/******** Small DEVICES ******/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__item-desc__container {
        display: flex;
        flex-direction: column;
    }

    .portfolio__item__main__container {
        margin: 1rem;
    }

    .portfolio__item__main {
        padding: 1rem;
    }

    .portfolio__item__quote {
        width: 100%;
    }

    .portfolio__item__quote p {
        font-size: 1rem;
        padding-bottom: 1.5rem;
        padding-top: 0;
    }

}
