.header {
    height: 110vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 7rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}



.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 3.5rem 1.5rem 1.5rem 1.5rem;
}


.me img {
    border-radius: 12rem 12rem 0 0;
}

/******** MEDIUM DEVICES ******/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/******** Small DEVICES ******/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scoll__down {
        display: none;
    }
}